@import "./styles/scss/mixins";
@import "./styles/scss/reset.scss";
@import "./styles/scss/antd-overrides.scss";

:root {
    --ufe-brand-blue: $brand-blue;
    --ufe-brand-light: $brand-light;
    --ufe-brand-light-100: $brand-light-100;
    --ufe-brand-light-300: $brand-light-300;

    --ufe-brand-blue: $brand-blue;
    --ufe-brand-blue-500: $brand-blue-500;

    --ufe-brand-dark: $brand-dark;
    --ufe-brand-dark-500: $brand-dark-500;
    --ufe-brand-dark-300: $brand-dark-300;
    --ufe-brand-dark-100: $brand-dark-100;
}
.ufe-release-block {
    padding: 1em;
    text-align: center;
    background: rgb(74, 69, 90);
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    overflow: hidden;
    position: fixed;
    display: flex;
    height: 100vh;
    align-items: center;
    z-index: 99999;
    width: 100%;
    .banner {
        position: relative;
        width: 208px;
    }
    span {
        text-align: left;
        font-size: 17px;
    }
}
* {
    &::-webkit-scrollbar {
        width: 7px;
        height: 7px;
    }

    &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-thumb {
        background: rgb(175, 175, 175);
        border-radius: 50px;
    }
}
body {
    margin: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", Arial,
        "Noto Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", sans-serif;
    // font-family: 'Poppins', system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", Arial, "Noto Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.utour-info {
    color: var(--ufe-blue);
    font-weight: 600;
    font-style: italic;
}
// .ufeBadge {
// 	position: relative;
// 	top: -11px;
// 	height: 17px;
// 	line-height: 1;
// 	right: 0;
// 	background: var(--ufe-blue);
// 	color: #fff;
// 	border-radius: 100px;
// 	padding: 2px 7px;
// 	font-size: 80%;
// 	font-weight: 500;
// 	@include max-sm {
// 		display: none;
// 	}
// }
.ufe-hidden-block {
    display: none;
}

.uLink {
    color: inherit;
    cursor: pointer;
}
.ant-layout-header {
    background: $base-background !important;
    padding: 0 24px;
    @include max-sm {
        padding: 0 10px !important;
        display: flex;
        justify-content: space-between;
    }
}
.ant-layout {
    background: #fbfbfb;
}
// .layout .logo img {
// 	width: 160px;
// 	padding: 6px;
// 	@include max-sm {
// 		width: 127px;
// 	}
// }
.layout .mobile-menu {
    @include max-md {
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}
// .header-options {
// 	@include min-md {
// 		float: right;
// 	}
// 	> div {
// 		display: inline-block;
// 		padding-right: 10px;
// 	}
// }
.ufe-no-content {
    flex-direction: column;
}
.ufe-no-content #emoji {
    font-size: 3em;
}
.ufe-full-center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.ufe-full-left {
    @extend .ufe-full-center;
    justify-content: left;
}
.ufe-main-layout {
    background: $base-background;
    padding: 15px;
    @include max-sm {
        padding: 8px;
    }
}
// .ufe-notifications .anticon {
// 	padding: 1em;
// 	border-radius: 100px;
// 	font-size: 16px;
// }
// .ufe-shop-options {
// 	#user {
// 		margin-right: 10px;
// 		@include max-md {
// 			display: none;
// 		}
// 	}
// }
// .ufe-menu-wrap {
//     display: flex;
// }
.ufe-menu {
    line-height: 64px !important;
    overflow: auto;
    flex: 1 1 auto;
    @include min-md {
        float: left;
    }
}
.header-options {
    flex: 0 1 auto;
    display: flex;
    overflow: hidden;
}
.ant-layout-footer {
    padding: 24px;
    background: $base-background;
}
.ant-menu-horizontal {
    border-bottom: none;
}
.ant-layout {
    min-height: 100vh;
}
.ufe-menu-icon {
    padding: 0 10px;
}
// Ant tab arrows animation to show hidden tabs
.ant-tabs:hover .ant-tabs-tab-next:not(.ant-tabs-tab-btn-disabled) svg {
    animation: sliding-right 1s infinite;
}
.ant-tabs:hover .ant-tabs-tab-prev:not(.ant-tabs-tab-btn-disabled) svg {
    animation: sliding-left 1s infinite;
}
.fc-widget-small .ufe-chat-msg {
    display: none;
}
.fc-widget-small.fc-open .ufe-chat-msg {
    display: block;
    position: absolute;
    top: 1px;
    background: #ff5a4e;
    color: #fff;
    left: 26px;
    border-radius: 5px 5px 0 0;
    right: 0;
    text-align: center;
    width: 300px;
    font-weight: bold;
    padding: 3px 0;
}
@keyframes sliding-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(25px);
        transform: translateX(25px);
    }
}
.site-card-border-less-wrapper {
    background: #ececec;
    margin-bottom: 10px;
}
.mt-10 {
    margin-top: 10px;
}
.ml-10 {
    margin-left: 10px;
}
.mr-10 {
    margin-right: 10px;
}

@keyframes sliding-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(-25px);
        transform: translateX(-25px);
    }
}
