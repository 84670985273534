@import "../scss/variables";
@import "../scss/animations.scss";

.ufe-space-between {
    display: flex;
    justify-content: space-between;
}
.ufe-full-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.ufe-horizontal-center {
    display: flex;
    justify-content: center;
}
.ufe-vertical-center {
    display: flex;
    align-items: center;
}
.ufe-flex-grow-shrink {
    flex: 1 1 auto;
}
.ufe-flex {
    display: flex;
}

.ant-popover-inner-content {
    padding: 6px 10px 8px;
    color: $brand-dark-300;
}

.ant-modal-content {
    border-radius: 17px;
}

.ufe-popover {
    max-width: 440px;
    max-height: 400px;
    overflow-y: auto;
    .ufe-row-item {
        padding: 5px;
        // width: 50%;
        display: inline-flex;
        border-radius: 7px;
        cursor: default;
        transition: all 0.3s ease;
        min-width: 200px;
        flex: 1 1 50%;
        &:hover {
            background: rgba(0, 0, 0, 0.02);
        }
        .ufe-row-item__title {
            flex: 1 1 auto;
            * + * {
                margin-left: 6px;
            }
            span:not(.productDisplay__image) {
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 124px;
                overflow: hidden;
            }
        }
    }
    .ant-form-item label > .anticon {
        vertical-align: middle;
    }
    .ant-typography strong {
        color: #353535;
        font-size: 12px;
    }
    .bottom-divider {
        padding-bottom: 3px;
    }
    .divider {
        margin: 1em 0 0.5em 0;
        border-top: 1px solid rgb(230, 230, 230);
        padding-top: 1em;
    }
    .popover-close {
        position: absolute;
        right: 10px;
        top: 15px;
    }
}
.ufe-close {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    padding: 0;
    background: transparent;
    color: rgba(0, 0, 0, 0.65);
    width: 27px;
    height: 27px;
    border-radius: 5px;
    transition: all 0.2s ease;
    cursor: pointer;
    &:hover {
        background: rgba(255, 176, 176, 0.2);
        color: #d61c1c;
    }
}

.ufe-delete-btn:hover {
    background: rgba(255, 176, 176, 0.2);
    color: #d61c1c;
}

.ant-tabs-tab {
    .badge {
        background: #505053;
        display: inline-flex;
        width: 18px;
        height: 18px;
        border-radius: 100px;
        text-align: center;
        font-size: 11px;
        font-weight: bold;
        color: $brand-light;
        margin-left: 5px;
    }
    &.ant-tabs-tab-active {
        .badge {
            background: $brand-light-300;
            color: $brand-dark-300;
        }
    }
}

.ufe-card {
    p {
        margin: 0;
    }
    .ant-checkbox-inner {
        border-radius: 4px;
    }
    .ufe-card__item {
        display: flex;
        width: 100%;
        flex-direction: column;
        > * + * {
            padding-top: 20px;
        }
        .ufe-card__item-row {
            > .ufe-card__item-col.ant-col-12:nth-child(2n) {
                padding-left: 16px;
            }
            > .ufe-card__item-col.ant-col-12:nth-child(n + 3) {
                padding-top: 12px;
            }
            > .ufe-card__item-col.ant-col-24:nth-child(n + 2) {
                padding-top: 12px;
            }
        }
    }
    .ufe-input__label {
        margin-right: 10px;
        flex: 1 0 auto;
        .anticon {
            margin-left: 10px;
            color: $brand-dark-300;
        }
    }
}

.ant-form-item-label {
    // margin-right: 10px;
    > label::after {
        content: none;
    }
}

.ufe-menu {
    .ant-menu-horizontal > .ant-menu-item-selected {
        border: none;
    }
    .ant-menu-horizontal > .ant-menu-item {
        font-weight: 500;
        color: #8a8d98;
    }
}

.ufe-btn {
    &-text {
        font-weight: 500;
        &:active,
        &:focus {
            background-color: rgba(176, 217, 255, 0.19);
            color: $brand-blue;
        }
        &--blue {
            background-color: rgba(176, 217, 255, 0.19);
            color: $brand-blue;
        }
    }
    &-bold {
        font-weight: 500;
    }
    &-switch {
        &--blue {
            &.ant-switch {
                background-color: rgba(0, 0, 0, 0.25);
                &.ant-switch-checked {
                    background-color: $brand-blue;
                }
                &:focus {
                    box-shadow: 0 0 0 2px rgba(2, 135, 255, 0.2);
                }
            }
        }
    }
    &--blue {
        background-color: $brand-blue;
        border-color: $brand-blue;
        &:hover,
        &:active,
        &:focus {
            background-color: $brand-blue;
            border-color: $brand-blue;
            opacity: 0.8;
        }
    }
    &--lines {
        background-image: url("../../images/general/button-lines.svg");
        background-size: 140%;
        background-position: 40% 55%;
        &:hover,
        &:active,
        &:focus {
            background-image: url("../../images/general/button-lines.svg") !important;
            background-position: 40% 55%;
            background-size: 145% !important;
        }
    }
    &-lg {
        padding: 12px 31px;
        height: auto;
    }
    &-auto-height {
        height: auto;
    }
}

.ufe-funnel-listing,
.ufe-dashboard__top-performing {
    .ant-table-tbody > tr.ant-table-row:hover > td {
        background: none;
    }
    .ant-table-tbody > tr > td {
        border-color: $brand-light-100;
    }
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
        padding: 20px 16px;
    }
    .ant-table-thead > tr > th {
        background: none;
        padding: 12px 16px 12px;
    }
}

.ufe-disabled {
    opacity: 0.7;
    background: rgba(0, 0, 0, 0.05);
}
.ufe-disabled-interaction {
    opacity: 0.7;
    background: rgba(0, 0, 0, 0.05);
    pointer-events: none;
}

h3 {
    &.ant-typography {
        margin: 0;
    }
}

.ufe-style {
    &--dashed-underline {
        border-bottom: 1px dashed;
        cursor: pointer;
    }
}
code {
    color: #eb5757;
    padding: 0.2em 0.4em;
    background: rgba(135, 131, 120, 0.15);
    border-radius: 3px;
    font-size: 85%;
    font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
}
