.ufe-upgrade-banner {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    background: #0087ff;
    color: #fff;
    width: 100%;
    height: 76px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}
