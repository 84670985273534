@import "./variables";

// chrome 83+ outline disable
*:focus {
    outline: none;
}

body {
    background-color: $base-background;
    color: $brand-dark;
}
