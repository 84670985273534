// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 1024px;

// Tablets and small desktops
$screen-lg-min: 1280px;

// Large tablets and desktops
$screen-xl-min: 1600px;

// Small devices
@mixin max-sm {
	@media (max-width: #{$screen-sm-min}) {
		@content;
	}
}

// Medium devices
@mixin max-md {
	@media (max-width: #{$screen-md-min}) {
		@content;
	}
}

// Large devices
@mixin max-lg {
	@media (max-width: #{$screen-lg-min}) {
		@content;
	}
}

// Extra large devices
@mixin max-xl {
	@media (max-width: #{$screen-xl-min}) {
		@content;
	}
}

@mixin min-sm {
	@media (min-width: #{$screen-sm-min}) {
		@content;
	}
}

// Medium devices
@mixin min-md {
	@media (min-width: #{$screen-md-min}) {
		@content;
	}
}

// Large devices
@mixin min-lg {
	@media (min-width: #{$screen-lg-min}) {
		@content;
	}
}

// Extra large devices
@mixin min-xl {
	@media (min-width: #{$screen-xl-min}) {
		@content;
	}
}
