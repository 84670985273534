.ufe-upgrade-content {
    text-align: center;
    .ant-btn {
        width: 50%;
        padding: 14px;
        height: auto;
    }
    &-text {
        margin: 22px 0;
        display: block;
        font-size: 26px;
    }
}
