@import "./variables";

.ufe-card {
    border-radius: 16px;
    margin-bottom: 20px;
    // overflow: hidden;
    width: 100%;
    box-shadow: 0 3px 10px -5px rgba(204, 204, 204, 0.65);
}
.ufe {
    .ant-input,
    .ant-select-selector,
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        // .ant-input {
        border: 1px solid rgba(0, 0, 0, 0.1);
    }
    .ant-drawer-footer {
        text-align: right;
        > * + * {
            margin-left: 10px;
        }
    }
    .ant-input-affix-wrapper {
        padding: 0 0 0 11px;
    }
    .ant-card-head {
        min-height: 0;
        border-bottom: none;
        // adjacent sibling selector
        + .ant-card-body {
            padding-top: 15px;
        }
        .ant-card-head-title {
            padding: 20px 0;
            padding-bottom: 0;
        }
        .ufe-title--subheading {
            font-size: 16px;
            margin: 0;
            .anticon {
                // color: $brand-dark-500;
            }
        }
    }
    .ant-form-item {
        margin: 0;
    }
    .ant-card-body {
        padding: 22px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    .ant-select-selector,
    .ant-input {
        min-height: 40px;
    }
    .ant-input-number-input {
        // 2px for border top and bottom, so overall 40px height
        min-height: 38px;
    }
    .ant-select {
        &:hover,
        &:active,
        &:focus {
            .ant-select-selector {
                border-color: $brand-dark-300;
            }
            .ant-select-arrow {
                color: $brand-dark-300;
            }
        }
        .ant-select-arrow {
            transition: all 0.2s ease;
        }
        &.ant-select-focused.ant-select-single:not(.ant-select-customize-input) {
            .ant-select-selector {
                border-color: $brand-dark;
            }
        }
    }
    .ant-input {
        &:hover,
        &:active,
        &:focus {
            border-color: $brand-dark-300;
        }
    }
    .ant-form-item {
        line-height: 1;
        flex: 1 1 auto;
    }
    .ant-form-item-label > label {
        height: 40px;
    }
    .ant-form-item-explain,
    .ant-form-item-extra {
        position: absolute;
        top: 100%;
        margin-left: 3px;
        z-index: 9;
        background: #fff;
        padding: 8px 12px;
        box-shadow: 0 0 23px rgba(0, 0, 0, 0.12);
        border-radius: 7px;
        line-height: 1.5;
    }
    .ant-form-item-control-input {
        min-height: 40px;
    }
}

.ufe-title--subheading {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 15px;
    color: $brand-dark;
    .anticon {
        margin-left: 10px;
        color: $brand-dark-300;
    }
}

.ant-select-single {
    .ant-select-selector {
        .ant-select-selection-item,
        .ant-select-selection-placeholder {
            line-height: 40px;
        }
    }
}
.ant-input-number {
    .ant-input-number-handler-wrap {
        display: none;
    }
}

.ant-btn {
    height: 40px;
    border-radius: 7px;
    &.ant-btn-lg {
        font-weight: 600;
    }
}

.ant-btn-sm {
    height: 29px !important;
    padding: 0px 9px;
}

.ufe-ico {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    padding: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-alert {
    // box-shadow: 1px 2px 4px -2px rgba(0, 0, 0, 0.09);
    border: none;
    padding: 10px 15px 10px 37px;
    margin-bottom: 10px;
    border-radius: 7px;
    border-left: 4px solid;
    width: 100%;
    &.ufe-alert {
        background-color: #fff;
    }
    &.alert-plain {
        background: no-repeat;
        border: none;
        padding: 10px 15px 10px 37px;
        margin: 0;
    }
    .ant-alert-icon {
        top: 14px;
        left: 16px;
        bottom: 0;
        align-items: baseline;
        display: flex;
    }
    &.ant-alert-success {
        // background-color: #d2f3b2;
        border-color: #3e9241;
        .ant-alert-message {
            color: #3e9241;
        }
    }
    &.ant-alert-info {
        background-color: #e3f3ff;
        border-color: #2196f3;
        .ant-alert-message {
            color: #1184e8;
        }
        .ant-alert-icon {
            color: #2196f3;
        }
    }
    &.ant-alert-warning {
        // background-color: #ffe58f;
        border-color: #ffcc24;
        .ant-alert-message {
            color: #a07f11;
        }
        .ant-alert-icon {
            color: #b59016;
        }
    }
}

.ant-form-item-has-error {
    .ant-input,
    .ant-input-affix-wrapper,
    .ant-input:hover,
    .ant-input-affix-wrapper:hover {
        border-color: #f5222d !important;
    }
}

/* 
Text type hover background opacity
*/
.ant-btn-text {
    &:hover,
    &:focus {
        background: rgba(0, 0, 0, 0.03);
    }
}

.ufe-create-funnel {
    .ant-form-item {
        margin-bottom: 0;
    }
}

/* 
Select box selected color
*/

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #d8dbdc;
}
