@import "./../../../../styles/scss/mixins";

.header-options {
    flex: 0 1 auto;
    display: flex;
    overflow: hidden;
}

.ufe-notifications .anticon {
    padding: 10px;
    margin-right: 10px;
    border-radius: 100px;
    font-size: 16px;
}

.ufe-shop-options {
    #user {
        margin-right: 10px;
        @include max-md {
            display: none;
        }
    }
}
.ufe-user-dropdown-menu {
    .ant-menu-item-selected {
        background-color: #fff !important;
    }
}
