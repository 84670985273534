@import './../../../styles/scss/mixins';

.logo img {
	width: 160px;
	padding: 6px;
	@include max-sm {
		width: 127px;
	}
}

.ufeBadge {
	position: relative;
	top: -11px;
	height: 17px;
	line-height: 1;
	right: 0;
	background: var(--ufe-blue);
	color: #fff;
	border-radius: 100px;
	padding: 2px 7px;
	font-size: 80%;
	font-weight: 500;
	@include max-sm {
		display: none;
	}
}

.mobile-menu {
	@include max-md {
		padding: 0 10px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
}

.ufe-menu-wrap {
    display: flex;
}