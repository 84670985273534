@keyframes shine {
    to {
        background-position:
            100% 0, /* move highlight to right */
    }
}

@keyframes ufe-rotate {
    0% {
        /* transform-origin: 50% 50%; */
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }

  @keyframes flash {
    0%, 50%, 100% {
       opacity: 1;
    }
    25%, 75% {
       opacity: .2;
    }
 }

  .ufe-flash-anim {
    animation: flash 1.5s infinite;
  }