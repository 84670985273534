@import './styles/scss/mixins';

.ufe-hidden-props {
    display: none;
}

.ublock .ant-typography {
	margin-bottom: 4px;
}
.ublock {
	text-align: center;
}
.ublock #subhead {
	font-size: 19px;
	color: #949494;
	padding-bottom: 1em;
	margin:0;
	@include max-sm {
		font-size: 15px;
	}
}
/* Small White Loader */
.ul-sm:before {
	width: 16px;
	height: 16px;
	margin-top: -8px;
	margin-left: -8px;
	border: 2px solid transparent;
	border-top-color: #ffffff;
}
.ufe-title-header {
    margin-bottom: 1.5em;
}
.ufe-title-header h2,.ufe-title-header h3,.ufe-title-header h4 {
    margin-bottom: 2px !important
}