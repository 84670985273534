$base-background: #F5F7F9;

$brand-light: #fff;
$brand-light-100: #f3f4f5;
$brand-light-300: #E6E9EE;

$brand-blue: #197CFF;
$brand-blue-alternate: #407BFF;
$brand-blue-500: rgba(25, 124, 255, 0.52);

$brand-dark: #1A242D;
$brand-dark-500: rgba(26, 36, 45, 0.77);
$brand-dark-400: #727475;
$brand-dark-300: #5a636b;
$brand-dark-300: rgba(34, 47, 64, 0.6);
$brand-dark-100: #969da5;

$brand-border-color: rgba(0, 0, 0, 0.07);
$border-radius: 16px;
$border-radius-medium: 11px;

$card-shadow: 7px -4px 15px rgba(0, 0, 0, 0.02), 0px 5px 6px rgba(0, 0, 0, 0.06);