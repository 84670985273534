@import '../../styles/scss/variables';
@import '../../styles/scss/mixins';

.ufe-layout {
	background: $base-background;
	padding: 24px;
	min-height: 400px;
	min-height: calc(100vh - 170px);
	position: relative;
    border-radius: 27px;
	// border: 1px solid rgba(0, 0, 0, 0.09);
	@include max-sm {
		padding: 15px;
	}
	// overflow: hidden;
}
.ant-layout-header, .ufe-layout {
    max-width: 1400px;
    margin: auto;
}
@include max-md {
	.ufe-layout .ant-table-wrapper {
		// responsive tables
		overflow-x: auto;
	}
}
.ufe-layout main {
	display: flex;
	align-items: center;
	justify-content: center;
}
main.ant-layout-content {
    min-height: calc(100vh - 200px) !important;
    // overflow-x: hidden;
}