@import './../../../../styles/scss/mixins';
@import './../../../../styles/scss/variables';

.ufe-menu {
	line-height: 64px !important;
	overflow: auto;
    flex: 1 1 auto;
    background: transparent !important;
    justify-content: flex-end;
    display: flex;
    overflow: hidden !important;
	@include min-md {
		float: left;
    }
    &.ant-menu-horizontal {
        > .ant-menu-item {
            font-weight: 500;
            color: $brand-dark-100;
            border-bottom-color: transparent;
            // text-shadow: 0 0 8px rgba(0, 0, 0, 0.23);
            &:hover {
                border-bottom-color: transparent;
            }
        }
        .ant-menu-item-active {
            border-bottom-color: transparent;
        }
        > .ant-menu-item-selected {
            border-bottom-color: transparent;
            color: $brand-dark;
        }
    }
}